
@font-face{
    font-family: 'Neon Tubes 2';
    src: url('https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/widget-source/fonts/NeonTubes2.otf')
}
.beatSaberHeartRate{
    color:white;
    font-size:200px;
    font-family: "Neon Tubes 2";
    text-shadow: 0 0 4px #FFF, -12px -8px 10px  #0090ffBB, 9px 10px 10px  #0090ffBB, -1px -1px 5px  #0090ff,  0 0 20px  #0090ff,  0 0 40px  #0090ff,0 0 80px  #0090ff, 0 0 28px #FFF , 0 0 8px #FFF ;
}


#line1, #line2, #line3 {
    color: #11a2b1;
    font-family: Helvetica Neue;
    line-height: 25px;
    text-shadow: -2px 0px 1px #121721;
    text-align: center;
}

#line1 {
    font-size: 45px;
}

#line2 {
    font-size: 40px;
}

#line3 {
    font-size: 29px;
}

@keyframes cyberpunk-heart-blink-keyframes {
    0%{ opacity: 1;}
    5% {
        opacity: 0;
    }
    9% {
        opacity: 1;
    }
    10% {
        opacity: 1;
        position: relative;
        top: -2px;
        left: 2px;

    }
    11% {
        opacity: 1;
    }
    12% {
        opacity: 0;
    }
    13% {
        opacity: 1;
    }
    15% {
        opacity: 1;
        position: relative;
        top: 1px;
        left: -2px;
    }
    30% {
        opacity: 0;
    }
    31% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    52% {
        opacity: 1;
    }
}
@-webkit-keyframes cyberpunk-heart-blink-keyframes {
    50% {
        opacity: 0;
    }
}
.container {
    position: relative;
    text-align: center;
    color: white;
}
/* Centered text */
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 300px;
    transform: translate(-50%, -50%);
    font-family: 'Rubik', sans-serif;
}